// Import Swiper React components
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSwiper } from 'swiper/react';
import { Button } from "antd";
import { Typography } from '../ant/typography';
import { useMediaQuery } from "../../hooks/useMediaQuery";
import classNames from "classnames";
import Logo from '../../assets/images/png/case_1.png'
import { Navigation, Pagination } from 'swiper/modules';
import LeftIcon from 'src/assets/images/svg/left.svg';
import RightIcon from 'src/assets/images/svg/right.svg';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// another styles
import './global-style.css';
import styles from './clider.module.css'


export const Slider = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const swiper = useSwiper();

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  const handleSwipe = (data: any) => {
    console.log('handleSwipe', data);
  }

  const img = <img src={Logo} className={styles.img} />;

  return (
    <Swiper
      className={styles.container}
      slidesPerView={isMobile ? 1 : 3}
      spaceBetween={60}
      hashNavigation={{
        watchState: true,
      }}
      pagination={{
        clickable: true,
      }}
      effect="fade"
      navigation={{
        prevEl: navigationPrevRef.current,
        nextEl: navigationNextRef.current,
      }}
      onBeforeInit={(swiper: any) => {
        swiper.params.navigation.prevEl = navigationPrevRef.current;
        swiper.params.navigation.nextEl = navigationNextRef.current;
      }}
      modules={[Navigation, Pagination]}
      onSlideChange={() => console.log('slide change')}
      onSwiper={handleSwipe}
      centeredSlides
      parallax
      loop
    >
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => (
        <SwiperSlide key={item} className={styles.slide}>
          {img}
          <div className={styles.slideInfo}>
            <Typography.Title>Rainbow Drop</Typography.Title>
            <Button type='primary' className={styles.slideButton}>Открыть за 100 USDT</Button>
          </div>
        </SwiperSlide>
      ))}
      <Button
        ref={navigationPrevRef}
        type='primary'
        shape='circle'
        className={classNames(styles.buttonsSwitchers, styles.buttonsLeft)}
      >
        <img src={LeftIcon} width={40} height={40} alt='left icon slider' />
      </Button>

      <Button
        ref={navigationNextRef}
        type='primary'
        shape='circle'
        className={classNames(styles.buttonsSwitchers, styles.buttonsRight)}
      >
        <img src={RightIcon} width={40} height={40} alt='right icon slider' />
      </Button>
    </Swiper>
  );
};
