import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { themeVariables } from './assets/theme';
import { PagesEnum } from './constants';
import { CasesContainer } from './pages/cases';
import { Header } from './components/header/Header';
import { HistoryContainer } from './pages/history/History';
import { WinContainer } from './pages/win/Win';

import './assets/scss/antd-rewrite.scss';
import './index.scss';

function App() {
  return (
    <div className='app'>
      <ConfigProvider theme={{ token: themeVariables }}>
        <Header />

        <Routes>
          <Route path={`${PagesEnum.Cases}`} element={<CasesContainer />} />
          <Route path={`${PagesEnum.History}`} element={<HistoryContainer />} />
          <Route path={`${PagesEnum.Win}`} element={<WinContainer />} />
        </Routes>
      </ConfigProvider>
    </div>
  );
}

export default App;
