import React from 'react';
import { Typography } from '../ant/typography';
import { Flex, Space } from "antd";
import { Col, Row } from 'antd';
import CaseItemsIcon from 'src/assets/images/png/case_items.png';
import CaseItems2Icon from 'src/assets/images/png/case_items2.png';

import styles from './caseItems.module.css';
import { useMediaQuery } from "../../hooks/useMediaQuery";

type Props = {

};

export const CaseItems = (props: Props) => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <div>
      <Flex justify='center' align='center' className={styles.headerText}>
        <Typography.Title>Содержимое сундука</Typography.Title>
      </Flex>

      <Row gutter={[12, 12]}>
        <Col span={isMobile ? 24 : 12}>
          <img src={CaseItemsIcon} className={styles.itemImg} />
        </Col>

        <Col span={isMobile ? 24 : 12}>
          <img src={CaseItems2Icon} className={styles.itemImg} />
        </Col>
      </Row>

      {/*<Row gutter={[12, 12]}>*/}
      {/*  <Col span={12}>*/}
      {/*    <Row gutter={[8, 8]}>*/}
      {/*      <Col span={24}>*/}
      {/*        /!* большой *!/*/}
      {/*        <div className={styles.block}></div>*/}
      {/*      </Col>*/}
      {/*      <Col span={8}>*/}
      {/*        /!* средний *!/*/}
      {/*        <div className={styles.block}></div>*/}
      {/*      </Col>*/}
      {/*      <Col span={8}>*/}
      {/*        /!* средний *!/*/}
      {/*        <div className={styles.block}></div>*/}
      {/*      </Col>*/}
      {/*      <Col span={8}>*/}
      {/*        /!* средний *!/*/}
      {/*        <div className={styles.block}></div>*/}
      {/*      </Col>*/}
      {/*    </Row>*/}
      {/*  </Col>*/}

      {/*  <Col span={12}>*/}
      {/*    <Row gutter={[8, 8]}>*/}
      {/*      <Col span={6}>*/}
      {/*        /!* малый *!/*/}
      {/*        <div className={styles.block}></div>*/}
      {/*      </Col>*/}
      {/*      <Col span={6}>*/}
      {/*        /!* малый *!/*/}
      {/*        <div className={styles.block}></div>*/}
      {/*      </Col>*/}
      {/*      <Col span={6}>*/}
      {/*        /!* малый *!/*/}
      {/*        <div className={styles.block}></div>*/}
      {/*      </Col>*/}
      {/*      <Col span={6}>*/}
      {/*        /!* малый *!/*/}
      {/*        <div className={styles.block}></div>*/}
      {/*      </Col>*/}
      {/*      <Col span={6}>*/}
      {/*        /!* малый *!/*/}
      {/*        <div className={styles.block}></div>*/}
      {/*      </Col>*/}
      {/*      <Col span={6}>*/}
      {/*        /!* малый *!/*/}
      {/*        <div className={styles.block}></div>*/}
      {/*      </Col>*/}
      {/*      <Col span={6}>*/}
      {/*        /!* малый *!/*/}
      {/*        <div className={styles.block}></div>*/}
      {/*      </Col>*/}
      {/*      <Col span={6}>*/}
      {/*        /!* малый *!/*/}
      {/*        <div className={styles.block}></div>*/}
      {/*      </Col>*/}

      {/*      <Col span={6}>*/}
      {/*        /!* малый *!/*/}
      {/*        <div className={styles.block}></div>*/}
      {/*      </Col>*/}
      {/*      <Col span={6}>*/}
      {/*        /!* малый *!/*/}
      {/*        <div className={styles.block}></div>*/}
      {/*      </Col>*/}
      {/*      <Col span={6}>*/}
      {/*        /!* малый *!/*/}
      {/*        <div className={styles.block}></div>*/}
      {/*      </Col>*/}
      {/*      <Col span={6}>*/}
      {/*        /!* малый *!/*/}
      {/*        <div className={styles.block}></div>*/}
      {/*      </Col>*/}
      {/*    </Row>*/}
      {/*  </Col>*/}
      {/*</Row>*/}

    </div>
  );
};
