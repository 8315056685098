import { TypographyText } from './text/TypographyText';
import { Typography as AntTypography } from 'antd';
import { TypographyShout } from './shout/TypographyShout';

interface TypographyComposition {
  Text: typeof TypographyText;
  Shout: typeof TypographyShout;
  Link: typeof AntTypography.Link;
  Paragraph: typeof AntTypography.Paragraph;
  Title: typeof AntTypography.Title;
}

export const Typography: TypographyComposition = {
  Text: TypographyText,
  Link: AntTypography.Link,
  Paragraph: AntTypography.Paragraph,
  Title: AntTypography.Title,
  Shout: TypographyShout,
};
