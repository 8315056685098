import { ThemeConfig } from 'antd';

export const themeVariables: ThemeConfig['token'] = {
  fontFamily: '"Montserrat", mono',
  colorText: '#fff',
  colorPrimary: '#52FF00',
  colorPrimaryText: '#3A59A9',
  colorTextSecondary: '#818C85',
  borderRadiusSM: 32,
  borderRadius: 32,
  borderRadiusLG: 32,
  controlHeightLG: 64,
  controlHeight: 48,
  controlHeightSM: 32,
  colorBgBase: '#2C3337',
};
