import React, { FC, PropsWithChildren, ReactNode } from 'react';
import styles from 'src/components/tags/tag/Tag.module.scss';
import classNames from 'classnames';
import { Label } from 'src/components/label/Label';

type Props = PropsWithChildren<{ isActive: boolean; onClick: () => void }>;

export const Tag: FC<Props> = (props) => {
  const { children, isActive, onClick } = props;

  return (
    <div onClick={onClick} className={classNames(styles.tag, { [styles.tag_active]: isActive })}>
      <Label size="middle">{children}</Label>
    </div>
  );
};

export type AltProps = PropsWithChildren<{
  onClick?: () => void;
  isActive?: boolean;
  type: 'default' | 'success' | 'danger';
  size?: 'small' | 'middle' | 'large';
  noborder?: boolean;
  nopadding?: boolean;
  clickable?: boolean;
  icon?: ReactNode;
  customClassname?: string;
}>;

export const AltTag: FC<AltProps> = (props) => {
  const {
    clickable,
    noborder,
    nopadding,
    children,
    type,
    onClick,
    size = 'small',
    isActive,
    customClassname,
  } = props;

  const hadleClick = () => {
    if (isActive) {
      return;
    }

    onClick?.();
  };

  return (
    <div
      onClick={hadleClick}
      className={classNames(styles.altTag, {
        [styles.altTag_defalut]: type === 'default',
        [styles.altTag_success]: type === 'success',
        [styles.altTag_danger]: type === 'danger',

        [styles.altTag_large]: size === 'large',
        [styles.altTag_noborder]: noborder,
        [styles.altTag_nopadding]: nopadding,
        [styles.altTag_clickable]: clickable,

        [styles.altTag_default_active]: type === 'default' && isActive,
        [styles.altTag_success_active]: type === 'success' && isActive,
        [styles.altTag_danger_active]: type === 'danger' && isActive,
      }, !!customClassname && customClassname)}
    >
      <Label size={size}>{children}</Label>
    </div>
  );
};
