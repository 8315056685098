import React, { useMemo, useState } from "react";
import { Button, Card, DatePicker, Flex, Table, TableColumnsType, Tag } from "antd";
import { Typography } from 'src/components/ant/typography';
import { mockTable } from "./mock";
import ErrorImage from 'src/assets/images/svg/error.svg';
import CheckIcon from "src/assets/images/svg/check.svg";
import { HistoryStatus, HistoryTableColumn } from "./types";
import locale from 'antd/es/date-picker/locale/ru_RU';

import styles from './history.module.css';
import { TagsFilter } from "../../components/tags-filter/TagsFilter";
import { PickerLocale } from "antd/lib/date-picker/generatePicker";
import { useTranslation } from "react-i18next";

const columns: TableColumnsType<HistoryTableColumn> = [
  {
    title: 'Название',
    dataIndex: 'name',
    render: (value, record) => (
      <Flex gap="small" justify='flex-start' align='center'>
        <img src={record.icon} width={44} height={44} />
        {value}
      </Flex>
    )
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    render: (value) => {
      if (value === HistoryStatus.Done) {
        return <Button disabled><img src={CheckIcon} alt={value} /> Выполнено</Button>
      }
      return <Button disabled danger><img src={ErrorImage} alt={value} />Ошибка при оплате</Button>
    },
  },
  {
    title: 'Сумма',
    dataIndex: 'sum',
  }
];

const statusFilters = [
  {
    title: 'Выполнено',
    value: HistoryStatus.Done,
  },
  {
    title: 'Ошибка при оплате',
    value: HistoryStatus.Error,
  },
]

export const HistoryContainer = () => {
  const { t } = useTranslation();

  const [filterStatus, setFilterStatus] = useState<HistoryStatus | undefined>(HistoryStatus.Done);
  const [filterDate, setFilterDate] = useState<string | null>(null);

  const ruLocale = useMemo<PickerLocale>(
    () => ({
      ...locale,
      lang: {
        ...locale.lang,
        rangePlaceholder: [
          t('Дата начала'),
          t('Дата конца'),
        ],
      },
    }),
    [t]
  );

  return (
    <div className={styles.container}>
      <Card bordered styles={ { body: { padding: '24px' } } }>
        <Flex justify='space-between' align='center' className={styles.header}>
          <Typography.Title>Фильтры</Typography.Title>
          <Typography.Text type='secondary' size='small'>Очистить</Typography.Text>
        </Flex>

        <Flex justify='space-between' align='center' wrap>
          <TagsFilter<HistoryStatus>
            className={styles.filterWrapper}
            title='По статусу:'
            onClick={setFilterStatus}
            list={statusFilters}
            active={filterStatus}
          />

          <Flex align='center' justify='flex-start'>
            <Typography.Text size="middle" className={styles.filterDate} strong>По дате:</Typography.Text>

            <DatePicker<string>
              locale={ruLocale}
              placeholder="Укажите дату"
              placement='topLeft'
              format="DD.MM.YYYY"
              value={filterDate}
              onChange={setFilterDate}
              className="ant-picker-small-padding ant-picker-huge ant-input-left-align"
            />
          </Flex>
        </Flex>
      </Card>

      <Table
        className={styles.table}
        columns={columns}
        dataSource={mockTable}
        rowKey='id'
        pagination={{ position: ['bottomCenter'] }}
      />
    </div>
  )
}
