import React, { useState } from 'react';
import { Button, Card } from "antd";
import WalletIcon from 'src/assets/images/svg/wallet.svg';
import { Typography } from 'src/components/ant/typography';
import { useMediaQuery } from 'src/hooks/useMediaQuery';

import styles from './balanceBlock.module.css';
import { DepositModal } from "./deposit-modal/DepositModal";

const ICON_PROPS = {
  width: 24,
  height: 24,
};

export const BalanceBlock = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleOpenModal = () => setIsOpenModal(true);

  const handleCloseModal = () => setIsOpenModal(false);

  if (isMobile) {
    return (
      <>
        <div className={styles.mobileContainer}>
          <div className={styles.mobileContent}>
            <img src={WalletIcon} {...ICON_PROPS} alt='wallet-icon' />
            <Typography.Title level={3}>
              Баланс: 1,300
              <Typography.Title level={3} type='secondary'>USDT</Typography.Title>
            </Typography.Title>
          </div>
          <Button size='small' className={styles.button} onClick={handleOpenModal}>Пополнить</Button>
        </div>
        {isOpenModal && <DepositModal onClose={handleCloseModal} />}
      </>
    )
  }

  return (
    <>
      <Card bordered styles={ { body: { padding: '12px' } } }>
        <div className={styles.container}>
          <img src={WalletIcon} {...ICON_PROPS} alt='wallet-icon' />
          <Typography.Title level={3} className={styles.text}>
            Баланс: 1,300
            <Typography.Title level={3} type='secondary'>USDT</Typography.Title>
          </Typography.Title>

          <Button size='small' className={styles.button} onClick={handleOpenModal}>Пополнить</Button>
        </div>
      </Card>

      {isOpenModal && <DepositModal onClose={handleCloseModal} />}
    </>
  );
};
