import React, { CSSProperties, FC, PropsWithChildren } from 'react';
import styles from './Label.module.scss';
import { SizeContextProps } from 'antd/es/config-provider/SizeContext';
import classNames from 'classnames';

interface Props extends PropsWithChildren<Partial<Pick<SizeContextProps, 'size'>>> {
  style?: CSSProperties;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  type?: 'base' | 'dark';
}

export const Label: FC<Props> = (props) => {
  const { size = 'middle', type = 'base', style, className, children, onClick, disabled } = props;

  return (
    <span
      onClick={onClick}
      style={style}
      className={classNames(className, styles.label, {
        [styles.label_small]: size === 'small',
        [styles.label_middle]: size === 'middle',
        [styles.label_large]: size === 'large',
        [styles.label_disabled]: disabled,
        [styles.label_dark]: type === 'dark',
      })}
    >
      {children}
    </span>
  );
};
