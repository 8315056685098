import { Typography } from '../ant/typography';
import React from 'react';
import MainLogo from '../../assets/images/png/mainLogo.png';

import styles from './header.module.css';
import { useLocation, useNavigate } from "react-router-dom";
import { PagesEnum } from "../../constants";
import { BalanceBlock } from "src/components/header/balanceBlock/BalanceBlock";

export const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = (e: any) => {
    switch (e.target.id) {
      case `main_tab_${PagesEnum.Cases}`:
        navigate(`/${PagesEnum.Cases}`);
        return;

      case `main_tab_${PagesEnum.Win}`:
        navigate(`/${PagesEnum.Win}`);
        return;

      case `main_tab_${PagesEnum.History}`:
        navigate(`/${PagesEnum.History}`);
        return;

      default:
        return;
    }
  }

  const casesTabType = location.pathname.includes(`/${PagesEnum.Cases}`) ? 'success' : undefined;
  const winTabType = location.pathname.includes(`/${PagesEnum.Win}`) ? 'success' : undefined;
  const historyTabType = location.pathname.includes(`/${PagesEnum.History}`) ? 'success' : undefined;

  return (
    <div className={styles.container}>
      <img src={MainLogo} className={styles.logo} alt='main-logo' />

      <div className={styles.tabs}>
        <Typography.Title id={`main_tab_${PagesEnum.Cases}`} level={2} type={casesTabType} onClick={handleClick}>
          Крипто-кейсы
        </Typography.Title>

        <Typography.Title id={`main_tab_${PagesEnum.Win}`} level={2} type={winTabType} onClick={handleClick}>
          Выигрыш
        </Typography.Title>

        <Typography.Title id={`main_tab_${PagesEnum.History}`} level={2} type={historyTabType} onClick={handleClick}>
          Транзакции
        </Typography.Title>
      </div>

      <BalanceBlock />
    </div>
  )
}
