import React, { useEffect, useRef } from 'react';
import { Card } from "antd";
import ItemCase from 'src/assets/images/png/item-case_1.png';
import RollerLine from 'src/assets/images/png/roller-line.png';

import styles from './rollerContainer.module.css';

export const RollerContainer = () => {
  const carouselRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const carousel = carouselRef.current;
    // @ts-ignore
    carousel.style.animation = `${styles.rotate} 5s linear`;

    const handleAnimationEnd = () => {
      // @ts-ignore
      carousel.style.animation = 'none';
    };

    // @ts-ignore
    carousel.addEventListener('animationend', handleAnimationEnd);

    return () => {
      // @ts-ignore
      carousel.removeEventListener('animationend', handleAnimationEnd);
    };
  }, []);

  return (
    <div className={styles.container}>
      <Card>
        <img src={RollerLine} className={styles.rollerLine} />
        <div ref={carouselRef} className={styles.itemsContainer}>
          {Array.from({ length: 30 }).map((_, index) => (
            <div className={styles.itemCase} key={index}>
              <img src={ItemCase} width={100} height={100} className={styles.itemCaseImg}/>
            </div>
          ))}
        </div>

      </Card>
    </div>
  );
};
