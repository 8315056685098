import React from "react";
import { Button, Card, Flex, Table } from "antd";
import WarningIcon from 'src/assets/images/svg/warning.svg';
import CheckIcon from 'src/assets/images/svg/check.svg';
import { Typography } from 'src/components/ant/typography';
import type { TableColumnsType } from 'antd'
import { StatusWin, WinTableColumn } from "./types";
import { mockTable } from "./mock";

import styles from './win.module.css';

const columns: TableColumnsType<WinTableColumn> = [
  {
    title: 'Название',
    dataIndex: 'name',
    render: (value, record) => (
        <Flex gap="small" justify='flex-start' align='center'>
          <img src={record.icon} width={44} height={44} />
          {value}
        </Flex>
      )
  },
  {
    title: 'Дата получения',
    dataIndex: 'date'
  },
  {
    title: '',
    dataIndex: 'status',
    render: (value) => {
      if (value === StatusWin.New) {
        return <Button type='primary' className={styles.statusButton}>Вывести</Button>
      }
      return <Button disabled className={styles.statusButton}><img src={CheckIcon} alt={value} />Выведен</Button>
    },
  },
];

export const WinContainer = () => {
  return (
    <div>
      <Card bordered styles={{ body: { padding: '16px 24px' }}} className={styles.cart}>
        <div className={styles.container}>
          <img src={WarningIcon} />
          <Typography.Text type='secondary' size='middle'>
            Наш менеджер свяжется с вами для уточнения деталей в течении 24 часов, после выставления заявки
          </Typography.Text>
        </div>
      </Card>

      <Table
        className={styles.table}
        columns={columns}
        dataSource={mockTable}
        rowKey='id'
        pagination={{ position: ['bottomCenter'] }}
      />
    </div>
  )
}
