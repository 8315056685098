import React from "react";
import { Slider } from "../components/slider/Slider";
import { CaseItems } from "../components/case-items/CaseItems";
import { RollerContainer } from "../components/RollerContainer/RollerContainer";
import { useMediaQuery } from "../hooks/useMediaQuery";

export const CasesContainer = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <div>
      <Slider />
      {!isMobile && <RollerContainer />}
      <CaseItems />
    </div>
  )
}
