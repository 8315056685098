import React, { FC } from 'react';
import { Typography } from 'antd';
import { TextProps } from 'antd/es/typography/Text';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import classNames from 'classnames';
import styles from './TypographyText.module.scss';

export interface TypographyTextProps extends TextProps {
  size?: SizeType;
}

export const TypographyText: FC<TypographyTextProps> = (props) => {
  const { size = 'middle', children, className, ...textProps } = props;

  return (
    <Typography.Text
      {...textProps}
      className={classNames(styles.typographyText, className, {
        [styles.typographyText_small]: size === 'small',
        [styles.typographyText_middle]: size === 'middle',
        [styles.typographyText_large]: size === 'large',
      })}
    >
      {children}
    </Typography.Text>
  );
};
