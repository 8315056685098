import React, { useState } from 'react';
import { Typography } from 'src/components/ant/typography';
import { Button, Flex, Form, Modal, Radio } from "antd";
import FiveBucksIcon from 'src/assets/images/png/5_bucks.png';
import TenBucksIcon from 'src/assets/images/png/10_bucks.png';

import styles from './depositModal.module.css';
import classNames from "classnames";
import { ManualDepositForm } from "./manual-form/ManualDepositForm";

type Props = {
  onClose: () => void;
};

enum DepositAmount {
  FiveBucks = 'FIVE',
  TenBucks = 'TEN',
  TwentyBucks = 'TWENTY'
  // TODO: добавить картинку после правок дизайнера
}

export const DepositModal = ({ onClose }: Props) => {
  const [selectedAmount, setSelectedAmount] = useState<DepositAmount | null>(null);
  const [isOpenManualAmount, setIsOpenManualAmount] = useState<boolean>(false);

  const handleSubmit = () => {
    console.log('handleSubmit');
  }

  const handleOpenManualAmount = () => setIsOpenManualAmount(true);
  const handleCloseManualAmount = () => setIsOpenManualAmount(false);

  return (
    <>
      <Modal
        open={!isOpenManualAmount}
        footer={null}
        onCancel={onClose}
      >
        <Form onFinish={handleSubmit}>
          <Flex justify='center' align='center' vertical>
            <Typography.Title>
              Выберите пакет
            </Typography.Title>

            <Typography.Text type='secondary' className={styles.secondaryText}>
              Обратите внимание, что баланс будет пополнен в течении 24 часов
            </Typography.Text>
          </Flex>

            {Object.values(DepositAmount).map((item) => {
              const isSelected = selectedAmount === item;
              const icon = item === DepositAmount.FiveBucks ? FiveBucksIcon : TenBucksIcon;
              const amount = item === DepositAmount.FiveBucks ? '5'
                : item === DepositAmount.TenBucks ? '10' : '20';
              const attempts = item === DepositAmount.FiveBucks ? '10'
                : item === DepositAmount.TenBucks ? '20' : '30';

              return (
                <div
                  key={item}
                  className={classNames(styles.radio, { [styles.radioActive]: isSelected } )}
                  onClick={() => setSelectedAmount(item)}
                >
                  <div className={styles.itemContent}>
                    <img src={icon} width={64} height={64} alt='amount' />
                    <div>
                      <Typography.Title level={5}>
                        {amount} USDT
                      </Typography.Title>

                      <Typography.Text type='secondary' size='small'>
                        Примерно {attempts} попыток
                      </Typography.Text>
                    </div>
                  </div>

                  <Radio checked={isSelected} />
                </div>
              );
            })}

          <div className={styles.footer}>
            <Button type='primary' size='large' block disabled={!selectedAmount} htmlType='submit'>Оплатить</Button>
            <Button type='text' style={{ color: '#52FF00' }} block onClick={handleOpenManualAmount}>Ввести сумму вручную</Button>
          </div>
        </Form>
      </Modal>

      {isOpenManualAmount && <ManualDepositForm onClose={handleCloseManualAmount} />}
    </>
  );
};
