import React, { FC } from 'react';
import { Typography } from 'antd';
import classNames from 'classnames';
import styles from './TypographyShout.module.scss';
import { TypographyTextProps } from '../text/TypographyText';
import { SizeType } from 'antd/es/config-provider/SizeContext';

type TypographyShoutProps = Omit<TypographyTextProps, 'size'> & {
    size?: SizeType | 'xs'
}

export const TypographyShout: FC<TypographyShoutProps> = (props) => {
    const { size = 'middle', children, className, ...textProps } = props;

    return (
        <Typography.Text
            {...textProps}
            className={classNames(styles.typographyShout, className, {
                [styles.typographyShout_extraSmall]: size === 'xs',
                [styles.typographyShout_small]: size === 'small',
                [styles.typographyShout_middle]: size === 'middle',
                [styles.typographyShout_large]: size === 'large',
            })}
        >
            {children}
        </Typography.Text>
    );
};
