import React, { FC } from 'react';
import { SpaceProps, Space as AntSpace } from 'antd';
import classNames from 'classnames';
import styles from './Space.module.scss';

type Props = SpaceProps & { full?: boolean };

export const Space: FC<Props> = (props) => {
  const { className, full = false, ...spaceProps } = props;
  return <AntSpace className={classNames(className, { [styles.space_full]: full })} {...spaceProps} />;
};
