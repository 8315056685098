
export enum HistoryStatus {
  Done = "DONE",
  Error = 'ERROR',
}

export type HistoryTableColumn = {
  id: string;
  name: string;
  icon: string;
  sum: string;
  status: HistoryStatus;
}
