export enum StatusWin {
  New = 'NEW',
  Done = 'DONE',
}

export type WinTableColumn = {
  id: string;
  name: string;
  icon: string;
  date: string;
  status: StatusWin;
}
