import React, { useState } from 'react';
import { Button, Flex, Input, Modal } from "antd";
import GoBackIcon from 'src/assets/images/svg/goBack.svg';
import { Typography } from '../../../../ant/typography';

import styles from './manual-deposit-form.module.css';

type Props = {
  onClose: () => void;
}

export const ManualDepositForm = ({ onClose }: Props) => {
  const [amount, setAmount] = useState(undefined);

  const handleSubmit = () => {
    console.log('handleSubmit_manual', { amount });
  }

  const handleChangeInput = (e: any) => {
    setAmount(e?.target?.value ?? undefined);
  }

  return (
    <Modal
      open
      title={<img className={styles.goBackImg} src={GoBackIcon} alt='GoBackIcon' onClick={onClose} />}
      footer={null}
      onCancel={onClose}
    >
      <Flex justify='center' align='center' vertical>
        <Typography.Title>
          Введите сумму пополнения
        </Typography.Title>

        <Typography.Text type='secondary' className={styles.secondaryText}>
          Обратите внимание, что баланс будет пополнен в течении 24 часов
        </Typography.Text>
      </Flex>

      <Typography.Text type='secondary' size='small'>Сумма</Typography.Text>
      <Input className={styles.input} type='number' size='large' suffix='USDT' value={amount} onChange={handleChangeInput} />

      <Button type='primary' size='large' block htmlType='submit' disabled={!amount} onClick={handleSubmit}>Оплатить</Button>
    </Modal>
  );
};
